import React, { useRef, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  height: full;
`;

const Vertical = ({ onActive }) => {
  const contentRef = useRef();
  const testAbRef = useRef(null);
  const [selectedButton, setSelectedButton] = useState("Education");

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    const index = ["Education", "Experience", "Skills"].indexOf(buttonName);
    if (index !== -1 && testAbRef.current) {
      const targetElement =
        testAbRef.current.querySelectorAll(".class_scroll")[index];
      const offsetTop =
        targetElement.getBoundingClientRect().top + window.pageYOffset;

      gsap.to(window, {
        duration: 1,
        scrollTo: { y: offsetTop, autoKill: false },
        ease: "power2.inOut",
      });
    }
  };

  useLayoutEffect(() => {
    // Chắc chắn rằng window và document đều sẵn sàng
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      ScrollTrigger.refresh();
    }
  }, []);

  useLayoutEffect(() => {
    const imagesLoaded = document.querySelectorAll("img");
    const totalImages = imagesLoaded.length;
    let imagesLoadedCount = 0;

    imagesLoaded.forEach((image) => {
      if (image.complete) {
        imagesLoadedCount++;
      } else {
        image.onload = () => {
          imagesLoadedCount++;
          if (imagesLoadedCount === totalImages) {
            // Khởi tạo ScrollTrigger sau khi tất cả hình ảnh đã tải xong
            ScrollTrigger.refresh();
          }
        };
      }
    });

    if (imagesLoadedCount === totalImages) {
      ScrollTrigger.refresh();
    }

    gsap.registerPlugin(ScrollTrigger);

    return () => {
      ScrollTrigger.getAll().forEach((st) => st.kill());
    };
  }, []);

  useGSAP(
    () => {
      const container = testAbRef.current;
      if (!container) return;

      const sections = gsap.utils.toArray(
        container.querySelectorAll(".class_scroll")
      );

      const scrollTween = gsap.to(sections, {
        yPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: container,
          pin: true,
          scrub: 1,
          snap: 1 / (sections.length - 1),
          start: "top top",
          end: () => "+=" + container.offsetHeight * (sections.length - 1),
        },
      });

      sections.forEach((section, i) => {
        ScrollTrigger.create({
          trigger: section,
          start: "top center",
          end: "bottom center",
          onEnter: () => setSelectedButton(["Education", "Education", "Experience", "Experience"][i]),
          onEnterBack: () => setSelectedButton(["Education", "Education", "Experience", "Experience"][i]),
        });
      });

      return () => {
        scrollTween.kill();
        ScrollTrigger.getAll().forEach((st) => st.kill());
      };
    },
    { scope: contentRef, dependencies: [] }
  );

  const renderButton = (name, image) => (
    <button
      onClick={() => handleButtonClick(name)}
      className={`w-[30px] md:w-full relative title overflow-hidden bg-[#161616] flex items-center justify-center gap-2 p-1 md:p-3 rounded-xl
                  hover:text-green-300 hover:bg-[#759a76] hover:delay-1000 hover:shadow-green
                  group ${
                    selectedButton === name
                      ? "text-green-300 bg-[#759a76] shadow-green"
                      : ""
                  }`}
      onMouseOver={() => {
        onActive(true);
      }}
      onMouseLeave={() => {
        onActive(false);
      }}
    >
      <span className="absolute top-0 -left-full w-full h-[2px] bg-gradient-to-r from-transparent to-[#759a76] group-hover:left-full duration-1000"></span>
      <span className="absolute -top-full right-0 w-[2px] h-full bg-gradient-to-b from-transparent to-[#759a76] group-hover:top-full group-hover:delay-[0.25s] duration-1000"></span>
      <span className="absolute bottom-0 -right-full w-full h-[2px] bg-gradient-to-l from-transparent to-[#759a76] group-hover:right-full group-hover:delay-500 duration-1000"></span>
      <span className="absolute -bottom-full left-0 w-[2px] h-full bg-gradient-to-t from-transparent to-[#759a76] group-hover:bottom-full group-hover:delay-[0.75s] duration-1000"></span>
      <img src={"/Img/" + image} className="w-[22px] h-[22px]" alt={name} />
      <h1 className="hidden md:block ml-2">{name}</h1>
    </button>
  );

  return (
    <Container className="z-10 bg-[#0d0d0d]" ref={contentRef}>
      <Section className="px-[5px] md:px-[50px]">
        <div className="flex justify-center" ref={testAbRef}>
          <section className="last w-screen flex justify-center h-screen test-ab text-white">
            <div className="flex justify-center items-center gap-5 w-full sm:w-11/12">
              <div className="mb-5 flex md:justify-between gap-2 md:gap-10 w-full">
                <div className="flex flex-col md:flex-row w-[30px] md:w-3/12 md:h-screen flex md:flex-col md:gap-5 mb-4 justify-center items-center">
                  {renderButton("Education", "1.png")}
                  {/* {renderButton("Skills", "2.png")} */}
                  {renderButton("Experience", "2.png")}
                </div>

                <div className="md:w-full h-screen flex items-center">
                  <div className="h-screen overflow-hidden">
                    {/* Education content */}
                    <div className="pt-7 overflow-hidden class_scroll h-full flex items-center ">
                      <div className=" rounded-3xl border-solid border-[1px] border-[#262626] bg-custom-box px-2 md:px-10 p-3 md:p-10">
                        <h2 className="Education title text-center">
                          Education
                        </h2>
                        <div className="flex w-full justify-center">
                          <img
                            src="/Img/tn.jpg"
                            alt="Chuyên viên làm web chuyên nghiệp tại Đà Nẵng"
                            className="w-full md:w-6/12 h-auto pb-2"
                          ></img>
                        </div>
                        <div className="text">
                          Professional programming training center IVietTech
                          2022 Graduated from NodeJs course
                          <p>March 2022 - Sep 2022</p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-5 overflow-hidden class_scroll h-full flex items-center ">
                      <div className=" rounded-3xl border-solid border-[1px] border-[#262626] bg-custom-box px-2 md:px-10 p-3 md:p-10">
                      <div className="flex w-full justify-center">
                          <img
                            src="/Img/tn3.jpg"
                            alt="Tư vấn phát triển website cho doanh nghiệp nhỏ Đà Nẵng"
                            className="w-full md:w-6/12 h-auto pb-2"
                          ></img>
                        </div>
                        
                        <div className="text">
                          The University of Danang - University of Science and
                          Technology Degree: Electronics and Telecommunications
                          Engineer
                          <p>Oct 2017 - Aug 2022</p>
                        </div>
                        {/* <div className="text ">
                        <div className="flex">
                          <img
                            src="/Img/tn.jpg"
                            alt=""
                            className="z-[1] w-4/12 ao-thun"
                          />
                          <img
                            src="/Img/tn2.JPG"
                            alt=""
                            className="z-[1] w-4/12 ao-thun"
                          />
                          <img
                            src="/Img/tn3.jpg"
                            alt=""
                            className="z-[1] w-4/12 ao-thun"
                          />
                        </div>
                      </div> */}
                      </div>
                    </div>

                    {/* Skills content */}
                    {/* <div className="mb-5 overflow-hidden class_scroll h-full flex items-center">
                      <div className=" rounded-3xl border-solid border-[1px] border-[#262626] bg-custom-box px-10 p-3 md:p-10">
                        <h2 className="Skills title">Skills</h2>
                      </div>
                    </div> */}

                    {/* Experience Content */}
                    <div className="mb-5 overflow-hidden class_scroll h-full flex items-center">
                      <div className="max-h-full overflow-auto rounded-3xl border-solid border-[1px] border-[#262626] bg-custom-box px-2 md:px-10 p-3 md:p-10">
                        <h2 className="Experience title text-center">
                          Experience
                        </h2>
                        <div className="text">
                          <p className="indent-3">
                            <strong>UNIFA Media company :</strong>
                          </p>
                          <p className="indent-3">
                            <strong>
                              Develop Backend - ( Aug 2022 - Sep 2023 ){" "}
                            </strong>
                          </p>
                          <p className="indent-3">
                            - Develop web-app for company using Express(NodeJS),
                            FastAPI(Python) and VueJS
                          </p>
                          <p className="indent-3">
                            - Apply new technologies - build a task management
                            system, dashboard for the company using Base
                            Larksuite.
                          </p>
                          <p className="indent-3">
                            - Server and database management with Cloudflare,
                            PHPMyadmin
                          </p>
                          <p className="indent-3">
                            - Improve, customize and fix errors in the process
                            of building websites with wordpress of UI/UX
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-5 overflow-hidden class_scroll h-full flex items-center ">
                      <div className=" rounded-3xl border-solid border-[1px] border-[#262626] bg-custom-box px-2 md:px-10 p-3 md:p-10">
                        <div className="text">
                          <p className="indent-3">
                            <strong>VisGroup company :</strong>
                          </p>
                          <p className="indent-3">
                            <strong>
                              Software - Engineer- ( Oct 2023 - Now ){" "}
                            </strong>
                          </p>
                          <p className="indent-3">
                            - Develop web-app for company using Express(NodeJS)
                            and ReactJS
                          </p>
                          <p className="indent-3">
                            - Develop automation tools in Python using PyQt5,
                            Selenium
                          </p>
                          <p className="indent-3">
                            - Apply new technologies - build a task management
                            system, dashboard.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Section>
    </Container>
  );
};

export default Vertical;
