import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./components/Page/Home/Home.jsx";
import Privacy from "./components/Page/Privacy & Cookie Policy/Privacy&CookiePolicy.jsx";
import Term from "./components/Page/Terms & Conditions/TermsConditions.jsx";
// import PageAboutUs from "./components/Page/PageAboutUs/AboutUsPage.js";
// import ContactsPage from "./components/Page/Contacts/ContactsPage.js";

import NotFound from "./components/Page/NotFound404/NotFound.js";
import { Helmet } from "react-helmet";

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  function getHeadData(pathname) {
    switch (pathname) {
      case "/":
        return {
          title: "Home - Võ Xuân Luân | Thiết Kế Website & Portfolio Chuyên Nghiệp",
          description: "Welcome to Luan Vo Porfolio. Xem các dự án thiết kế website chuyên nghiệp của Võ Xuân Luân, bao gồm các website thương mại điện tử, website cá nhân và doanh nghiệp. Đánh giá chất lượng từng dự án!",
        };
      case "/abouts":
      case "/abouts/":
        return {
          title: "About Me - Learn more about me.",
          description: "Learn more about me.",
        };
      case "/contacts":
      case "/contacts/":
        return {
          title: "Contacts.",
          description: "Contact for more information.",
        };
      default:
        return { title: "Võ Xuân Luân | Thiết Kế Website & Portfolio Chuyên Nghiệp", description: "Tìm kiếm lập trình viên web chuyên nghiệp tại Đà Nẵng? Tôi cung cấp dịch vụ phát triển website chuẩn SEO và giao diện tối ưu cho doanh nghiệp của bạn." };
    }
  }

  const { title, description } = getHeadData(location.pathname);


  return (
    <div className="app">
      <div className="app-body">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta
            name="keywords"
            content="voxuanluan, business, development, partnership, portfolio, Luan Vo ,portfolio thiết kế web, dự án thiết kế website, web thương mại điện tử, Web developer tại Đà Nẵng, Lập trình viên web Đà Nẵng, Freelancer web Đà Nẵng, Thiết kế website Đà Nẵng, Dịch vụ lập trình web Đà Nẵng, Dịch vụ phát triển website tại Đà Nẵng, Freelancer thiết kế website Đà Nẵng, Lập trình viên website chuyên nghiệp Đà Nẵng, Phát triển web cá nhân, Chuyên viên phát triển front-end và back-end, Chuyên viên phát triển web WordPress tại Đà Nẵng, Thiết kế website chuẩn SEO tại Đà Nẵng, Dịch vụ phát triển web theo yêu cầu, Lập trình website PHP, JavaScript, WordPress, Chuyên viên làm web chuyên nghiệp tại Đà Nẵng, Tư vấn phát triển website cho doanh nghiệp nhỏ Đà Nẵng, Thiết kế website cá nhân Đà Nẵng"
          />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://voxuanluan97.site/Img/Home.png"
          />
        </Helmet>
        <div className="w-full mx-0 my-auto block">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/term" element={<Term />} />
            {/* <Route path="/abouts" element={<PageAboutUs />} />
            <Route path="/contacts" element={<ContactsPage />} /> */}
            
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
