import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

export const effect_gsap = (param_clas, y, mobile) => {
  return gsap.fromTo(
    param_clas,
    {
      opacity: 0,
      y: -100,
    },
    {
      opacity: 1,
      y,
      ease: "power1.inOut",
      scrollTrigger: {
        trigger: param_clas,
        start: mobile ? "top center" : "top 85%",
        end: mobile ? "1% center" : "1% 85%",
        toggleActions: "play none none reverse",
        scrub: 4,
        duration: 2,
        // markers: mobile ? true : false
      },
    }
  );
};

export const effect_out = (param_clas, y, mobile) => {
  return gsap.fromTo(
    param_clas,
    {
      y: 0,
    },
    {
      opacity: 0,
      y,
      ease: "power1.inOut",
      scrollTrigger: {
        trigger: param_clas,
        start: mobile ? "bottom 40%" : "90% center",
        end: mobile ? "bottom 40%" : "bottom center",
        toggleActions: "complete reverse reverse complete",
        scrub: 4,
        duration: 2,
        // markers: mobile ? true : false
        // markers: true
      },
    }
  );
};

// Cái này ở  Establish
export const move_year = (param_class_move, param_ref, y) => {
  return gsap.fromTo(
    param_class_move,
    { opacity: 0, y },
    {
      opacity: 1,
      y: 0,
      ease: "power2.out",
      scrollTrigger: {
        trigger: param_ref,
        start: "top 40%",
        end: "bottom top",
        toggleActions: "play none none reverse",
        scrub: 6,
        duration: 15,
        //   markers: true
      },
    }
  );
};

export const effect_img = (param_clas, x = 0, y = 0) => {
  return gsap.fromTo(
    param_clas,
    { opacity: 0, x, y },
    {
      opacity: 1,
      x: 0,
      y: 0,
      ease: "power3.out",
      scrollTrigger: {
        trigger: param_clas,
        start: "top center",
        end: "center center",
        toggleActions: "play none none reverse",
        scrub: 6,
        duration: 15,
        // markers: true
      },
    }
  );
};

export const EffectSplitText = (param_clas, text) => {
  const tl = gsap.timeline({ paused: true });
  tl.fromTo(
    param_clas,
    { opacity: 0, scale: 1.2, y: 50, x: -50, duration: 2 },
    { opacity: 1, x: 350, y: -250 }
  )
    .to(param_clas, {
      duration: 4,
      text,
      // scaleY: 0.5,
      x: 250,
      y: -250,

      ease: "power3.out",
    })
    .to(param_clas, {
      duration: 2,
      scale: 1,
      x: 0,
      y: 0,
      ease: "bounce.out",
    });

  ScrollTrigger.create({
    trigger: param_clas,
    start: "top bottom",
    end: "bottom top",
    onEnter: () => tl.play(),
    onLeave: () => tl.reverse(),
    // onEnterBack: () => tl.play(),
    // onLeaveBack: () => tl.reverse(),
    markers: true,
  });
};

export const EffectFadeIn = (param_clas) => {
  gsap.fromTo(
    param_clas,
    {
      y: 100,
      autoAlpha: 0,
    },
    {
      y: 0,
      autoAlpha: 1,
      duration: 1,
      ease: "back.out(1.7)",
      scrollTrigger: {
        trigger: param_clas,
        start: "top bottom-=5%",
        end: "top center",
        scrub: 1,
        // markers: true,
      },
    }
  );
};

export const EffectTextClassic = (param_clas, delay=0) => {
  const element = document.querySelector(param_clas);
  element.innerHTML = element.textContent.replace(
    /\S/g,
    "<span class='letter'>$&</span>"
  );

  gsap.fromTo(
    `${param_clas} .letter`,
    {
      opacity: 0,
      y: 50,
    },
    {
      opacity: 1,
      y: 0,
      duration: 0.8,
      stagger: 0.01, // Độ trễ giữa mỗi chữ
      ease: "power3.out",
      delay,
      scrollTrigger: {
        trigger: param_clas,
        start: "top bottom-=100", // Bắt đầu khi phần tử cách bottom của viewport 100px
        end: "bottom center",
        toggleActions: "play none none reverse",
        // markers: true, // Bỏ comment nếu muốn xem markers
      },
    }
  );
};
