import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Contact from "../../Dependence/Contact/Contact.js";
import Fotter from "../../HeaderFotter/Fotter/Fotter.js";
import FixedNav from "../../HeaderFotter/FixedNav/Fixednav.js";
import BlurryCursor from "../../Dependence/Cursor/cursor.jsx";
import Horizontal from "./horizontal.jsx";
import HeaderNav from "../../HeaderFotter/Header/Header.js";
// import Project from "./Project.jsx";
import Vertical from "./vertical.jsx";
import ProjectVertical from "./Project.jsx";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { useGSAP } from "@gsap/react";

import AnimatedText from "./AnimatedTextGSAP.jsx";
import {
  EffectFadeIn,
  EffectTextClassic,
} from "../../../gsapHelper/effectGsap.js";

import "./style.css";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // line-height: 1.5;
  font-size: 15px;
  font-weight: 300;
  color: #7d7f78;
`;
const Section = styled.section`
  height: full;
  // width: 100vw;
`;

const lock_frame = (trigger) => {
  gsap.timeline({
    scrollTrigger: {
      trigger: trigger,
      // pin: true,
      snap: 1 / 2,
    },
  });
};

const Home = () => {
  gsap.registerPlugin(useGSAP);
  const content = useRef();
  const videoRef = useRef(null);
  const loading_page = useRef(null);
  const name_container = useRef(null);
  const bottom_loading = useRef(null);
  const rate_percent = useRef(null)
  const fotter = useRef(null)
  
  const home = useRef(null)
  const project = useRef(null)
  const contact = useRef(null)
  const info = useRef(null)

  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const handleScroll = (param) => {
    if (param === "home") {
      home.current?.scrollIntoView({ behavior: "smooth" });
    } else if (param === "project") {
      project.current?.scrollIntoView({ behavior: "smooth" });
    } else if (param === "info") {
      info.current?.scrollIntoView({ behavior: "smooth" });
    } else if (param === "contact") {
      contact.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  //  Hàm để truyền xuống prop cho component con, component con có thể tự cập nhật lại vào đây.
  const handleActivation = (active) => {
    setIsActive(active);
  };

  gsap.registerPlugin(ScrollTrigger, TextPlugin);

  useEffect(() => {
    // Chắc chắn rằng window và document đều sẵn sàng
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      ScrollTrigger.refresh();
    }
  }, []);

  useEffect(() => {
    const imagesLoaded = document.querySelectorAll("img");
    const totalImages = imagesLoaded.length;
    let imagesLoadedCount = 0;

    imagesLoaded.forEach((image) => {
      if (image.complete) {
        imagesLoadedCount++;
      } else {
        image.onload = () => {
          imagesLoadedCount++;
          if (imagesLoadedCount === totalImages) {
            // Khởi tạo ScrollTrigger sau khi tất cả hình ảnh đã tải xong
            ScrollTrigger.refresh();
          }
        };
      }
    });

    if (imagesLoadedCount === totalImages) {
      ScrollTrigger.refresh();
    }
    gsap.registerPlugin(ScrollTrigger);
  }, []);

  useEffect(() => {
    // Giả lập quá trình tải trang với setInterval
    const loadingInterval = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(loadingInterval);
          hideLoadingScreen();
          return 100;
        }
        return prevProgress + 1;
      });
    }, 30); // Tăng dần phần trăm tải sau mỗi 30ms

    return () => {
      // Dọn dẹp setInterval khi component bị unmount
      clearInterval(loadingInterval);
    };
  }, []);

  const hideLoadingScreen = () => {
    // Sử dụng GSAP để ẩn loading screen
    gsap.to(rate_percent.current, {
      duration: 1,
      opacity: 0,
      onComplete: () => {
        gsap.to(loading_page.current, {
          duration: 1.5,
          opacity: 0,
          display: "none",
        });
      },
    });
  };


  useGSAP(
    () => {
      lock_frame(videoRef.current);

      // Page 1
      // Effect text 1
      const tl1 = gsap.timeline({ repeat: -1 });
      tl1
        .to(".Hello", { duration: 1, text: "Xin chào!" })
        .to(".Hello", { duration: 1, text: "Hello!" }, "+=1")
        .to(".Hello", { duration: 1, text: "Bonjour!" }, "+=1");

      // Effect text 2
      const tl2 = gsap.timeline({ repeat: -1 });
      tl2
        .to(".Welcome", { duration: 1, text: "Chào mừng bạn!" })
        .to(".Welcome", { duration: 1, text: "" }, "+=1")
        .to(".Welcome", { duration: 1, text: "Rất vui được gặp bạn!" }, "+=0.5")
        .to(".Welcome", { duration: 1, text: "" }, "+=1")
        .to(".Welcome", { duration: 1, text: "Nice to meet you!" }, "+=0.5")
        .to(".Welcome", { duration: 1, text: "" }, "+=1");

      // Page 2:
      // const text = `Its Luan! Luan would like to briefly introduce himself. I'm a
      // programmer from Vietnam. My main job is web development. I also
      // create automation tools in Python. I'm passionate about building
      // efficient and user-friendly applications. My expertise includes
      // front-end technologies like React and Vue.js, as well as
      // back-end frameworks such as Node.js and FastAPI. Inspired by
      // clean code principles and agile methodologies, I strive to
      // deliver high-quality software solutions.`;
      // EffectSplitText(".Introduct", text)
      EffectTextClassic(".Introduct");
      EffectTextClassic(".Des-Introduct", 3);

      EffectFadeIn(".Tittle-Introduct");

      // gsap.fromTo(
      //   loading_page.current,
      //   { opacity: 1 },
      //   {
      //     opacity: 0,
      //     display: "none",
      //     duration: 1.5,
      //     delay: 4,
      //   }
      // );

      gsap.fromTo(
        name_container.current,
        {
          y: 50,
          autoAlpha: 0,
        },
        {
          y: 0,
          autoAlpha: 1,
          duration: 2,
          delay: 0.5,
        }
      );

      // ""
      // ""
      // Cái này chuyển sang tl với scrolltrigger hay hơn nè, commit tại đây
      gsap.fromTo(
        bottom_loading.current,
        { opacity: 0, display: "none" },
        {
          opacity: 1,
          display: "flex",
          duration: 1.5,
          delay: 1,
          scrollTrigger: {
            trigger: fotter.current,
            start: "bottom-=10 bottom", // Bắt đầu khi phần tử cách bottom của viewport 100px
            end: "bottom center",
            toggleActions: "play none none reverse",
            // markers: true,
          },
        }
      );

      // gsap.fromTo(
      //   ".icon-scroll",
      //   { opacity: 1 },
      //   {
      //     opacity: 0,
      //     scrollTrigger: {
      //       trigger: ".section1",
      //       scrub: 2,
      //       start: "bottom 80%",
      //       end: "bottom 80%",
      //     },
      //   }
      // );
    },
    { scope: content }
  );

  return (
    <Container
      ref={content}
      // className="bg-gradient-to-r from-[#416428b0] via-red-500/50 to-[#4c44efa3]"
    >
      <HeaderNav onActive={handleActivation} handleScroll={handleScroll}/>

      <Section className="section1 " ref={home}>
        <div className="relative video1" ref={videoRef}>
          <div className="absolute z-20 bg-[#3d323271] p-10 w-full h-full text-white">
            <div className="absolute top-20 md:top-52 md:left-28 w-full md:w-2/6 drop-shadow-2xl">
              <div className="Hello text-clamp-100 font-bold">Welcome</div>
              <div className="Welcome text-clamp-40 "></div>
            </div>

            <div className="absolute top-1/2 right-1/2 translate-x-1/2 md:right-1/4 w-full md:w-2/6 drop-shadow-2xl text-center">
              <div className="relative perspective-400">
                <span className="text-clamp-50 font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#80a7a673] via-[#c19f9f82] to-white">
                  Portfolio
                </span>
              </div>
              <div className="relative perspective-400">
                <span className="text-clamp-40 font-light effect2 font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#80a7a673] via-[#c19f9f82] to-white">
                  Full Stack Developer
                </span>
              </div>
            </div>
            {/* <div className="absolute bottom-36 left-20 w-full md:w-2/6">
              <div className="text-clamp-50 uppercase font-bold pb-5">Welcome,</div>
              <div className="text-clamp-40">Portfolio</div>
              <div className="text-clamp-40 font-light">Full Stack Developer</div>
            </div> */}
          </div>

          <div className="video-content relative w-full h-screen overflow-hidden">
            <video
              id="background-video"
              autoPlay
              muted
              loop
              playsInline
              className="absolute z-10  top-1/2 left-1/2 min-w-full min-h-full w-auto h-auto object-cover"
              style={{ transform: `translateX(-50%) translateY(-50%)` }}
            >
              <source src="/Video/1.MP4 " type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="icon-scroll fixed z-20">
          <div className="mouse">
            <div className="wheel"></div>
          </div>
          <p className="scroll">Scroll</p>
          <div className="icon-arrows">
            <span></span>
          </div>
        </div>
      </Section>

      <Section className="section2">
        <div className="relative video1 h-screen" ref={videoRef}>
          <div className="absolute z-10 bg-[#3d323271] md:p-10 w-full h-full text-white">
            <div className="absolute top-20 right-10 md:w-2/6 text-right">
              <AnimatedText
                text={"Welcome"}
                className={"text-2xl uppercase font-bold md:pb-5 effect1"}
              />
              {/* <div className="text-10 md:text-clamp-30 ">Portfolio</div> */}

              <AnimatedText text={"Portfolio"} className={"md:text-clamp-30"} />
              <AnimatedText
                text={"Full Stack Developer"}
                className={"md:text-clamp-30"}
              />
              {/* <div className="text-10 md:text-clamp-30">
                Full Stack Developer
              </div> */}
            </div>

            {/* Bottom */}
            <div className="absolute w-full md:pr-10 md:w-5/6 bottom-0 md:bottom-28 rounded-3xl border-solid border-[1px] border-[#262626] bg-bg-info md:px-10 p-3 md:p-10 max-h-full overflow-auto">
              {/* <div className="Tittle-Introduct text-clamp-50 font-[500]"></div> */}
              <div className="Introduct w-full text-justify text-10 md:text-[20px]">
                Hello, It's Luan! I would like to briefly introduce himself. I'm
                a programmer from Vietnam. My main job is web development. I
                also create automation tools in Python. I design and develop
                custom websites, from simple landing pages to complex web apps.
                <div> My services include: Developing landing pages and professional, optimized
                  business websites. Building powerful and secure web apps. Creating e-commerce websites using WordPress WooCommerce. Optimizing website performance and security.
    
                I use modern technologies such as React, Vue.js, Node.js,
                FastAPI, and Python to ensure high-quality, user-friendly, and
                easily manageable products.
                </div>
                </div> 
                {/* I'm passionate about building
                efficient and user-friendly applications. My expertise includes
                front-end technologies like React and Vue.js, as well as
                back-end frameworks such as Node.js and FastAPI. Inspired by
                clean code principles and agile methodologies, I strive to
                deliver high-quality software solutions. */}
              
              <div className="Des-Introduct pt-1">
                {" "}
                Welcome to my portfolio - I hope you find it interesting!
              </div>
              <div className="pt-1 flex justify-between w-full text-[10px] text-[#7d7f78]">
                <a
                  className="flex justify-left items-center md:pb-3"
                  href="https://linkedin.com/in/xluan8997"
                  target="_blank"
                  rel="noreferrer"
                  onMouseOver={() => {
                    setIsActive(true);
                  }}
                  onMouseLeave={() => {
                    setIsActive(false);
                  }}
                >
                  {" "}
                  <img
                    src="/Img/SocialIcon/insa.png"
                    className="pr-3 w-10"
                    alt="Web developer tại Đà Nẵng"
                  />{" "}
                  <div className="hidden md:block">
                    linkedin.com/in/xluan8997
                  </div>
                </a>

                <button
                  className="flex justify-left items-center md:pb-3"
                  onClick={() =>
                    window.open("mailto:voxuanluan97@gmail.com", "_blank")
                  }
                  onMouseOver={() => {
                    setIsActive(true);
                  }}
                  onMouseLeave={() => {
                    setIsActive(false);
                  }}
                >
                  <img
                    src="/Img/SocialIcon/email.png"
                    className="pr-3 w-10"
                    alt="Lập trình viên web Đà Nẵng"
                  />
                  <div className="hidden md:block">voxuanluan97@gmail.com</div>
                </button>

                <a
                  className="flex justify-left items-center md:pb-3"
                  href="https://linkedin.com/in/xluan8997"
                  target="_blank"
                  rel="noreferrer"
                  onMouseOver={() => {
                    setIsActive(true);
                  }}
                  onMouseLeave={() => {
                    setIsActive(false);
                  }}
                >
                  {" "}
                  <img
                    src="/Img/SocialIcon/link.png"
                    className="pr-3 w-10"
                    alt="Freelancer web Đà Nẵng"
                  />{" "}
                  <div className="hidden md:block">
                    linkedin.com/in/xluan8997
                  </div>
                </a>

                <a
                  className="flex justify-left items-center pb-3"
                  href="https://fb.com/xuanluan.vo.121"
                  target="_blank"
                  rel="noreferrer"
                  onMouseOver={() => {
                    setIsActive(true);
                  }}
                  onMouseLeave={() => {
                    setIsActive(false);
                  }}
                >
                  <img
                    src="/Img/SocialIcon/fba.png"
                    className="pr-3 w-10"
                    alt="Thiết kế website Đà Nẵng"
                  />
                  <div className="hidden md:block">fb.com/xuanluan.vo.121</div>
                </a>
              </div>
            </div>
          </div>

          <div className="video-content relative w-full h-screen overflow-hidden">
            <video
              id="background-video"
              autoPlay
              muted
              loop
              playsInline
              className="absolute top-1/2 left-1/2 min-w-full min-h-full w-auto h-auto object-cover"
              style={{ transform: `translateX(-50%) translateY(-50%)` }}
            >
              <source src="/Video/2.mp4 " type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </Section>

      <div ref={project}>
        <ProjectVertical onActive={handleActivation} />
      </div>

      <div ref={info}>
        <Horizontal ref={info}/>
      </div>

      <Vertical onActive={handleActivation} />

      {/* <Project /> */}
      <div ref={contact}>
        <Contact className=" relative z-9" onActive={handleActivation} ref={contact}/>
      </div>

      {/* <Section>
         <div className="video-content w-full ">
          <video
            id="background-video"
            autoPlay
            muted
            loop
            playsInline
            className="fixed z-[0] top-1/2 left-1/2 min-w-full min-h-full w-auto h-auto object-cover"
            style={{ transform: `translateX(-50%) translateY(-50%)` }}
          >
            <source src="/Video/bg_v2.mp4 " type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div> 
      </Section> */}

      <div ref={fotter}>
        <Fotter onActive={handleActivation} />
      </div>
      <FixedNav onActive={handleActivation} handleScroll={handleScroll}/>
      <BlurryCursor isActive={isActive} />
      <div className="loading-page" ref={bottom_loading}>
        <svg
          id="logo_svg"
          width="238"
          height="335"
          viewBox="0 0 238 335"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="b"
            d="M226.43,261.7l-2.01,58.62-202.32,1.9m204.33-60.52l-29.8-17.77H43.08m-20.98,78.29l-19.6-17.37v-14.6m19.6,31.97h0M81.7,31.99V232.51h-14.12M81.7,31.99L51.17,8.22C28.76-2.82,16.03,3.26,2.5,16.76V243.93c.71,9.12,6.97,12.21,18.93,11.74m46.16-23.16l-20.39-6.98m-4.11,18.4V44.06c6.86-12.07,18.27-12.07,24.5,0V232.51h14.12V31.99c-23.39-19.37-34.5-18.89-52.54,1.9l-3.92,221.78c-1.15,5.51,2.6,11.24,3.92,13.65,1.72,3.13,5.97,4.26,13.92,3.81H213.45v31.73H22.1v17.37l202.32-1.9,2.01-58.62H73.51c-19.27,1.06-34.68-1.25-30.43-17.77V44.06m146.84,246.2H2.5l19.6,14.6-19.6-14.6H189.92l23.53,14.6-23.53-14.6v-12.6m-167.82,27.2v17.37h0"
          />
        </svg>
        
        
        {/* <div className="name-container">
          <div className="logo-name">Luan Vo</div>
        </div>
        <div className="social" id="sc_bottom">
          <ul className="social_ul">
            <li>
              <a href="https://fb.com/xuanluan.vo.121" target="_blank">
                <span></span>
                <span className="fa fa-facebook"></span>
              </a>
            </li>
            <li>
              <a
                href="#"
                onclick="window.open('mailto:voxuanluan97@gmail.com', '_blank');"
              >
                <span></span>
                <span className="fa fa-google"></span>
              </a>
            </li>
            <li>
              <a href="https://linkedin.com/in/xluan8997" target="_blank">
                <span></span>
                <span className="fa fa-linkedin"></span>
              </a>
            </li>
          </ul>
        </div> */}
      </div>

      <div className="loading-page" ref={loading_page}>
        <svg id="logo_svg" width="238" height="335" viewBox="0 0 238 335" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path className="b"
            d="M226.43,261.7l-2.01,58.62-202.32,1.9m204.33-60.52l-29.8-17.77H43.08m-20.98,78.29l-19.6-17.37v-14.6m19.6,31.97h0M81.7,31.99V232.51h-14.12M81.7,31.99L51.17,8.22C28.76-2.82,16.03,3.26,2.5,16.76V243.93c.71,9.12,6.97,12.21,18.93,11.74m46.16-23.16l-20.39-6.98m-4.11,18.4V44.06c6.86-12.07,18.27-12.07,24.5,0V232.51h14.12V31.99c-23.39-19.37-34.5-18.89-52.54,1.9l-3.92,221.78c-1.15,5.51,2.6,11.24,3.92,13.65,1.72,3.13,5.97,4.26,13.92,3.81H213.45v31.73H22.1v17.37l202.32-1.9,2.01-58.62H73.51c-19.27,1.06-34.68-1.25-30.43-17.77V44.06m146.84,246.2H2.5l19.6,14.6-19.6-14.6H189.92l23.53,14.6-23.53-14.6v-12.6m-167.82,27.2v17.37h0" />
        </svg>
        <div className="name-container" ref={name_container}>
          <div className="logo-name">Luan Vo</div>
        </div>
        <div ref={rate_percent}>
          <p className="text-center font-bold text-[30px] pt-10">{loadingProgress} %</p>
          <p>loading ...</p>
        </div>
      </div>
    </Container>
  );
};

export default Home;
