import React, { useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import "./style.css";

import AnimatedText from "./AnimatedTextGSAP.jsx";
import { EffectTextClassic } from "../../../gsapHelper/effectGsap.js";

gsap.registerPlugin(ScrollTrigger);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 300;
  color: #7d7f78;
`;

const Section = styled.section`
  height: full;
`;

const Horizontal = () => {
  const content = useRef();
  const sliderRef = useRef(null);

  window.addEventListener("load", () => {
    ScrollTrigger.refresh();
  });

  useGSAP(
    () => {
      if (!sliderRef.current) return;

      EffectTextClassic(".firt-text");

      const sections = gsap.utils.toArray(
        sliderRef.current.querySelectorAll("section")
      );

      const scrollTween = gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: sliderRef.current,
          pin: true,
          scrub: 0.5,
          snap: 1 / (sections.length - 1),
          start: "top top",
          end: () => "+=" + sliderRef.current.offsetWidth,
        },
      });

      sections.forEach((el) => {
        const bgHong = el.querySelector(".bg-hong");
        const aoThun = el.querySelector(".ao-thun");
        const signature = el.querySelector(".signature");
        const des_text = el.querySelector(".des-text");

        if (des_text) {
          des_text.innerHTML = des_text.textContent.replace(
            /\S/g,
            "<span class='letter'>$&</span>"
          );
          const text_animation = el.querySelectorAll(".des-text .letter");

          gsap.fromTo(
            text_animation,
            { opacity: 0, y: 50 },
            {
              opacity: 1,
              y: 0,
              duration: 0.8,
              stagger: 0.01, // Độ trễ giữa mỗi chữ
              ease: "power3.out",
              scrollTrigger: {
                containerAnimation: scrollTween, //Chú ý đến cái này để kich hoạt sự kiện đúng
                trigger: des_text,
                start: "center bottom-=100",
                end: "70% bottom-=200",
                toggleActions: "play none none reverse",
              },
            }
          );
        }

        if (bgHong) {
          gsap.fromTo(
            bgHong,
            { opacity: 0, transform: "translateY(-30%)" },
            {
              opacity: 1,
              transform: "translateY(20%)",
              scrollTrigger: {
                containerAnimation: scrollTween,
                trigger: bgHong,
                start: "top bottom",
                end: "+=50%",
                scrub: 0.5,
              },
            }
          );
        }

        if (aoThun) {
          gsap.fromTo(
            aoThun,
            { transform: `translateY(50%)`, opacity: 0 },
            {
              opacity: 1,
              y: 0,
              ease: "power1.inOut",
              scrollTrigger: {
                containerAnimation: scrollTween,
                trigger: aoThun,
                start: "top bottom",
                end: "+=50%",
                scrub: 0.5,
              },
            }
          );
        }

        if (signature) {
          gsap.fromTo(
            signature,
            { x: 200, opacity: 0 },
            {
              opacity: 1,
              x: 0,
              ease: "none",
              scrollTrigger: {
                containerAnimation: scrollTween,
                trigger: signature,
                start: "top bottom",
                end: "+=30%",
                scrub: 0.5,
                onLeave: () => {
                  gsap.set(signature, { opacity: 0 }); // Ẩn khi không cần thiết
                },
              },
            }
          );
        }
      });
    },
    { scope: content }
  );

  return (
    <Container className="z-10 bg-[#0d0d0d]" ref={content}>
      <Section>
        <div className="relative flex w-[300%]" ref={sliderRef}>
          <section className=" w-full last h-screen">
            <div className="h-full sm:h-[80vh] my-[5%] w-screen relative flex items-end text-white ">
              {/* Description */}
              <div className="flex justify-center gap-5 w-full sm:w-11/12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <div className="w-11/12 md:w-8/12 flex flex-col items-center">
                  <div class="flex items-center justify-center gap-2">
                    <img src="/Img/icon.png" className="w-[22px] h-[22px]" alt="Dịch vụ lập trình web Đà Nẵng"/>
                    <h1 className="uppercase font-bold text-[15px] sm:text-[20px]">
                      Personal Information
                    </h1>
                    
                    
                  </div>
                  <p className="text-[70px] pt-10">➯</p>
                  {/* <div className="title"></div> */}
                </div>
              </div>
            </div>
          </section>

          <section className="w-full h-full mid ">
            <div className="h-full sm:h-[80vh] my-[5%] w-screen relative flex items-end pb-6 text-white ">
              <div className="w-11/12 sm:w-6/12 absolute top-1/2 left-1/2 pb-10 -translate-x-1/2 -translate-y-3/4 sm:-translate-y-[60%]">
                <div className="relative flex flex-col items-center">
                  <img
                    src="/anh2.png"
                    // src="fullstack.png"
                    alt="Dịch vụ phát triển website tại Đà Nẵng"
                    className="z-[1] w-3/12 ao-thun"
                  ></img>
                  <div
                    id="thanh_den"
                    style={{ width: "100%" }}
                    className="z-[1]"
                  >
                    {" "}
                  </div>
                  <div className="absolute z-0 top-0 sm:top-[15%] ml-[-7%] bg-[#dd8e8e] w-[85%] sm:w-[45%] h-[75%] sm:h-[60%] bg-hong"></div>

                  <div
                    className='signature absolute right-[10%] top-[15%] font-["Timberline"]'
                    style={{ transform: "rotate(-10deg)" }}
                  >
                    Luan Vo
                  </div>
                </div>
              </div>

              {/* Description */}
              <div className="w-full flex flex-col items-center">
                <div class="flex items-center">
                  <div class="flex items-center justify-center gap-2">
                    <img src="/Img/icon.png" className="w-[22px] h-[22px]" alt="Freelancer thiết kế website Đà Nẵng"/>
                    <AnimatedText
                      text={"INTRODUCTION: "}
                      className="font-bold text-[15px] sm:text-[20px]"
                    />
                  </div>
                </div>

                <p className="firt-text text-justify text-[15px] sm:text-[20px] w-11/12 sm:w-9/12 leading-6 font-bold text-transparent bg-clip-text bg-custom-gradient">
                  I am an introvert but like to travel, camp, coffee, chat, and
                  joke with friends. I graduated from The University of Danang -
                  University of Science and Technology in 2022 majoring in
                  Electronics and Telecommunications and have been working
                  Backend Developer using python and NodeJS until now.
                </p>
              </div>
            </div>
          </section>

          <section className="w-full first h-full">
            <div className="h-full sm:h-[80vh] my-[5%] w-screen relative flex items-end pb-6 text-white ">
              <div className="w-11/12 sm:w-6/12 absolute top-1/2 left-1/2 pb-10 -translate-x-1/2 -translate-y-[115%] sm:-translate-y-[60%]">
                <div className="relative flex flex-col items-center">
                  <div className='text-clamp-40 font-[400] md:pb-10 font-["Timberline"]'>
                    Full-stack Developer
                  </div>

                  <img
                    // src="/Img/chill.png"
                    src="fullstack.png"
                    alt="Lập trình viên website chuyên nghiệp Đà Nẵng"
                    className="z-[1] w-6/12 ao-thun"
                  ></img>
                  <div
                    id="thanh_trang"
                    style={{ width: "100%", marginBottom: 0, zIndex: 1 }}
                  ></div>

                  <div className='hidden md:block font-["AmericanTypewriter"] text-[12px] sm:text-[12px]'>
                    {/* 1 */}
                    <div className="absolute left-0 top-3/4 text-blue-400 tl-up">
                      JavaScript
                    </div>
                    {/* 2 */}
                    <div className="absolute left-[20%] top-1/2 tl-down">
                      Python
                    </div>
                    {/* 3 */}
                    <div className="absolute left-0 top-1/4 text-blue-400 tl-up">
                      NodeJS - ( ExpressJS )
                    </div>
                    {/* 4 */}
                    <div className="absolute left-[20%] top-0 tl-down">
                      MySQL
                    </div>
                    {/* 5 */}
                    <div className="absolute left-0 -top-[10%] text-blue-400 tl-up">
                      HTML5 & CSS3
                    </div>
                    {/* 6 */}
                    <div className="absolute right-0 -top-[10%] text-blue-400 tl-r-up">
                      MongoDB
                    </div>
                    {/* 7 */}
                    <div className="absolute right-[20%] top-0 tl-r-down">
                      Git
                    </div>
                    {/* 8 */}
                    <div className="absolute right-0 top-1/4 text-blue-400 tl-r-up">
                      Jenkins
                    </div>
                    {/* 9 */}
                    <div className="absolute right-[20%] top-2/4 tl-r-down">
                      Docker
                    </div>
                    {/* 10 */}
                    <div className="absolute right-0 top-3/4 text-blue-400 tl-r-up">
                      Linux
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-center">
                <div className="text-justify text-[15px] sm:text-[20px] w-11/12 sm:w-9/12 leading-6">

                  <div class="flex items-center justify-center gap-2">
                    <img src="/Img/icon.png" className="w-[22px] h-[22px]" alt="Phát triển web cá nhân"/>
                    <h1 className="uppercase font-bold text-[15px] sm:text-[20px]">
                      Experience:
                    </h1>
                  </div>

                  <p className="des-text font-bold text-transparent bg-clip-text bg-custom-gradient">
                    Web developer is my first job after graduating, now I have 2
                    years of experience in this field. Throughout my
                    professional journey, I've consistently embraced and
                    implemented cutting-edge technologies in web programming.
                    This hands-on experience has not only broadened my skill set
                    but also deepened my understanding of the ever-evolving web
                    development landscape. My commitment to staying current with
                    industry trends has enabled me to deliver modern, efficient,
                    and innovative solutions to complex web challenges.
                  </p>
                </div>
              </div>
            </div>
          </section>

          
        </div>
      </Section>
      {/* <Section>
        <div className="logo-container">
          <img src="/Img/logo.png" alt=""></img>
          <img src="/Img/logo.png" alt=""></img>
          <img src="/Img/logo.png" alt=""></img>
          <img src="/Img/logo.png" alt=""></img>
          <img src="/Img/logo.png" alt=""></img>
          <img src="/Img/logo.png" alt=""></img>
          <img src="/Img/logo.png" alt=""></img>
          <img src="/Img/logo.png" alt=""></img>
          <img src="/Img/logo.png" alt=""></img>
          <img src="/Img/logo.png" alt=""></img>
          <div className="icon-scroll">
            <div className="mouse">
              <div className="wheel"></div>
            </div>
            <p className="scroll">Scroll</p>
            <div className="icon-arrows">
              <span></span>
            </div>
          </div>
        </div>
      </Section> */}
    </Container>
  );
};

export default Horizontal;
