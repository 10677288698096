import React, { useState } from "react";
import styled from "styled-components";
import Fotter from "../../HeaderFotter/Fotter/Fotter.js";
import BlurryCursor from "../../Dependence/Cursor/cursor.jsx";
import HeaderNavTerm from "../../HeaderFotter/Header/HeaderTerm.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // line-height: 1.5;
  font-size: 15px;
  font-weight: 300;
  color: #7d7f78;
`;
const Section = styled.section`
  height: full;
  // width: 100vw;
`;

const Privacy = () => {
  const [isActive, setIsActive] = useState();

  //  Hàm để truyền xuống prop cho component con, component con có thể tự cập nhật lại vào đây.
  const handleActivation = (active) => {
    setIsActive(active);
  };

  return (
    <Container className="z-10 bg-[#0d0d0d]">
      <HeaderNavTerm onActive={handleActivation} />

      <Section className="section2">
        <div className="video1 pt-20 p-5  min-h-screen flex items-center">
          <div>
            <div className="z-10 md:p-10  text-white text">
              {/* Bottom */}

              <div className="Tittle-Introduct text-clamp-50 font-[500]">
                Privacy & Cookie Policy
              </div>
              <div className="Introduct w-full text-justify text-10 md:text-[20px]">
                <p className="indent-3 pb-2">
                  Introduction We value the privacy of our visitors and are
                  committed to protecting your personal information. This
                  Privacy & Cookie Policy outlines how we collect, use, and
                  safeguard your data when you visit our website.
                </p>
                <p className="indent-3 pb-2">Information Collection and Use </p>
                <p className="indent-6">
                  - Personal Information: We may collect personal information
                  such as your name, email address, and contact details when you
                  voluntarily provide it to us for inquiries, service requests,
                  or subscriptions.
                </p>
                <p className="indent-6">
                  - Cookies: Our website uses cookies to enhance user
                  experience, analyze site usage, and improve our services.
                  Cookies are small data files stored on your device that help
                  us remember your preferences and collect analytics data.
                </p>
                <p className="indent-3 pb-2">
                  Data Protection We implement robust security measures to
                  protect your personal information from unauthorized access,
                  alteration, disclosure, or destruction.
                </p>
                <p className="indent-3 pb-2">
                  Your Rights You have the right to access, correct, or delete
                  your personal information stored with us. Please contact us if
                  you wish to exercise these rights.
                </p>

                <p className="indent-3 pb-5">
                  Contact Us If you have any questions or concerns about our
                  Privacy & Cookie Policy, please contact us at{" "}
                  <button
                    onClick={() =>
                      window.open("mailto:voxuanluan97@gmail.com", "_blank")
                    }
                    onMouseOver={() => {
                      setIsActive(true);
                    }}
                    onMouseLeave={() => {
                      setIsActive(false);
                    }}
                  >
                    
                    <div className="text-blue-400">
                    [voxuanluan97@gmail.com].
                    </div>
                  </button>
                </p>
              </div>
              {/* I'm passionate about building
                efficient and user-friendly applications. My expertise includes
                front-end technologies like React and Vue.js, as well as
                back-end frameworks such as Node.js and FastAPI. Inspired by
                clean code principles and agile methodologies, I strive to
                deliver high-quality software solutions. */}

              <div className="Des-Introduct pt-10 pb-5 text-center">
                {" "}
                Welcome to my portfolio - I hope you find it interesting!
              </div>
              <div className="pt-1 flex justify-between w-full text-[10px] text-[#7d7f78]">
                <a
                  className="flex justify-left items-center md:pb-3"
                  href="https://linkedin.com/in/xluan8997"
                  target="_blank"
                  rel="noreferrer"
                  onMouseOver={() => {
                    setIsActive(true);
                  }}
                  onMouseLeave={() => {
                    setIsActive(false);
                  }}
                >
                  {" "}
                  <img
                    src="/Img/SocialIcon/insa.png"
                    className="pr-3 w-10"
                    alt="Instaicon"
                  />{" "}
                  <div className="hidden md:block">
                    linkedin.com/in/xluan8997
                  </div>
                </a>

                <button
                  className="flex justify-left items-center md:pb-3"
                  onClick={() =>
                    window.open("mailto:voxuanluan97@gmail.com", "_blank")
                  }
                  onMouseOver={() => {
                    setIsActive(true);
                  }}
                  onMouseLeave={() => {
                    setIsActive(false);
                  }}
                >
                  <img
                    src="/Img/SocialIcon/email.png"
                    className="pr-3 w-10"
                    alt="icon"
                  />
                  <div className="hidden md:block">voxuanluan97@gmail.com</div>
                </button>

                <a
                  className="flex justify-left items-center md:pb-3"
                  href="https://linkedin.com/in/xluan8997"
                  target="_blank"
                  rel="noreferrer"
                  onMouseOver={() => {
                    setIsActive(true);
                  }}
                  onMouseLeave={() => {
                    setIsActive(false);
                  }}
                >
                  {" "}
                  <img
                    src="/Img/SocialIcon/link.png"
                    className="pr-3 w-10"
                    alt="icon"
                  />{" "}
                  <div className="hidden md:block">
                    linkedin.com/in/xluan8997
                  </div>
                </a>

                <a
                  className="flex justify-left items-center pb-3"
                  href="https://fb.com/xuanluan.vo.121"
                  target="_blank"
                  rel="noreferrer"
                  onMouseOver={() => {
                    setIsActive(true);
                  }}
                  onMouseLeave={() => {
                    setIsActive(false);
                  }}
                >
                  <img
                    src="/Img/SocialIcon/fba.png"
                    className="pr-3 w-10"
                    alt="icon"
                  />
                  <div className="hidden md:block">fb.com/xuanluan.vo.121</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Fotter onActive={handleActivation} />
      <BlurryCursor isActive={isActive} />
    </Container>
  );
};

export default Privacy;
