import React, { useRef, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { useGSAP } from "@gsap/react";
import AnimatedText from "./AnimatedTextGSAP.jsx";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  height: full;
`;

const ProjectVertical = ({ onActive }) => {
  const contentRef = useRef();
  const testAbRef = useRef(null);
  const [selectedButton, setSelectedButton] = useState("Ecommrce");

  //click scroll đến nội dung
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    const index = ["Ecommrce", "WebApp", "AutoTool"].indexOf(buttonName);

    if (index !== -1 && testAbRef.current) {
      const targetElement =
        testAbRef.current.querySelectorAll(".class_scroll")[index];
      const offsetTop =
        targetElement.getBoundingClientRect().top + window.pageYOffset;

      gsap.to(window, {
        duration: 1,
        scrollTo: { y: offsetTop, autoKill: false },
        ease: "power2.inOut",
      });
    }
  };

  //đảm bảo loading page
  useLayoutEffect(() => {
    // Chắc chắn rằng window và document đều sẵn sàng
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      ScrollTrigger.refresh();
    }
  }, []);

  useLayoutEffect(() => {
    const imagesLoaded = document.querySelectorAll("img");
    const totalImages = imagesLoaded.length;
    let imagesLoadedCount = 0;

    imagesLoaded.forEach((image) => {
      if (image.complete) {
        imagesLoadedCount++;
      } else {
        image.onload = () => {
          imagesLoadedCount++;
          if (imagesLoadedCount === totalImages) {
            // Khởi tạo ScrollTrigger sau khi tất cả hình ảnh đã tải xong
            ScrollTrigger.refresh();
          }
        };
      }
    });

    if (imagesLoadedCount === totalImages) {
      ScrollTrigger.refresh();
    }

    gsap.registerPlugin(ScrollTrigger);

    return () => {
      ScrollTrigger.getAll().forEach((st) => st.kill());
    };
  }, []);

  useGSAP(
    () => {
      const container = testAbRef.current;
      if (!container) return;

      const sections = gsap.utils.toArray(
        container.querySelectorAll(".class_scroll")
      );

      // scroll dọc
      const scrollTween = gsap.to(sections, {
        yPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: container,
          pin: true,
          scrub: 1,
          snap: 1 / (sections.length - 1),
          start: "top top",
          end: () => "+=" + container.offsetHeight * (sections.length - 1),
        },
      });

      sections.forEach((section, i) => {
        ScrollTrigger.create({
          trigger: section,
          start: "top center",
          end: "bottom center",
          onEnter: (triggerInstance) => {
            // Đổi từ 'self' thành 'triggerInstance'
            if (triggerInstance.direction === 1) {
              // Kiểm tra hướng cuộn lên
              setSelectedButton(
                [
                  "Project",
                  "Ecommrce",
                  "Ecommrce",
                  "WebApp",
                  "WebApp",
                  "AutoTool",
                  "AutoTool",
                ][i]
              );
            }
          },
          onEnterBack: (triggerInstance) => {
            // Đổi từ 'self' thành 'triggerInstance'
            if (triggerInstance.direction === -1) {
              // Kiểm tra hướng cuộn lên
              setSelectedButton(
                [
                  "Project",
                  "Ecommrce",
                  "Ecommrce",
                  "WebApp",
                  "WebApp",
                  "AutoTool",
                  "AutoTool",
                ][i]
              );
            }
          },
        });
      });

      return () => {
        scrollTween.kill();
        ScrollTrigger.getAll().forEach((st) => st.kill());
      };
    },
    { scope: contentRef, dependencies: [] }
  );

  // render
  const renderButton = (name, image) => (
    <button
      onClick={() => handleButtonClick(name)}
      className={`w-[30px] md:w-full relative title overflow-hidden bg-[#161616] flex items-center justify-center gap-2 p-1 md:p-3 rounded-xl
                  hover:text-green-300 hover:bg-[#759a76] hover:delay-1000 hover:shadow-green
                  group ${
                    selectedButton === name
                      ? "text-green-300 bg-[#759a76] shadow-green"
                      : ""
                  }`}
      onMouseOver={() => {
        onActive(true);
      }}
      onMouseLeave={() => {
        onActive(false);
      }}
    >
      <span className="absolute top-0 -left-full w-full h-[2px] bg-gradient-to-r from-transparent to-[#759a76] group-hover:left-full duration-1000"></span>
      <span className="absolute -top-full right-0 w-[2px] h-full bg-gradient-to-b from-transparent to-[#759a76] group-hover:top-full group-hover:delay-[0.25s] duration-1000"></span>
      <span className="absolute bottom-0 -right-full w-full h-[2px] bg-gradient-to-l from-transparent to-[#759a76] group-hover:right-full group-hover:delay-500 duration-1000"></span>
      <span className="absolute -bottom-full left-0 w-[2px] h-full bg-gradient-to-t from-transparent to-[#759a76] group-hover:bottom-full group-hover:delay-[0.75s] duration-1000"></span>
      <img src={"/Img/" + image} className="w-[22px] h-[22px]" alt={name} />
      <h1 className="hidden md:block ml-2">{name}</h1>
    </button>
  );

  return (
    <Container className="z-10 bg-[#0d0d0d]" ref={contentRef}>
      <Section className="px-[5px] md:px-[50px]">
        <div className="flex justify-center" ref={testAbRef}>
          <section className="last w-screen flex flex-col justify-center h-full test-ab text-white">
            <div className="flex justify-center items-center gap-5 w-full">
              <div className="flex md:justify-between gap-2 md:gap-10 w-full">
                <div className="md:w-full h-screen flex items-center">
                  <div className="h-full overflow-hidden">
                    {/* Headder */}
                    <div className="class_scroll h-full flex items-center overflow-hidden">
                      <div className="mt-10 text-center">
                        <div class="flex items-center justify-center gap-2">
                          <img
                            src="/Img/icon.png"
                            className="w-[22px] h-[22px]" alt=""
                          />
                          <AnimatedText
                            text={"Project: "}
                            className="uppercase font-bold text-[15px] sm:text-[20px]"
                          />
                        </div>
                        <div>
                          Here are some of the projects I have worked on,
                          ranging from corporate website development, landing
                          pages to complex web applications. Each project is
                          customized to the client's requirements, ensuring
                          quality, performance and optimal user experience.
                        </div>
                      </div>
                    </div>

                    {/* Ecommrce content */}
                    <div className="class_scroll h-full flex items-center overflow-hidden">
                      <div className="w-full rounded-3xl border-solid border-[1px] border-[#262626] bg-custom-box md:px-10 p-3 md:p-10 max-h-full">
                        <h2 className="Ecommrce title text-center">Ecommrce</h2>

                        {/* image */}
                        <div className="flex w-full justify-center">
                          <img
                            src="/project/woo.jpg"
                            alt="Chuyên viên phát triển front-end và back-end"
                            className="w-full md:w-10/12 h-auto pb-2"
                          ></img>
                        </div>

                        <div className="text">
                        Developing an online sales website. The main goal is to provide an online sales platform that is easy to manage, expand and support customers in online shopping. Customers can manage products and orders themselves without much technical knowledge.
                          {/* Việc phát triển một trang web bán hàng trực tuyến. Mục
                          tiêu chính là cung cấp một nền tảng bán hàng trực
                          tuyến dễ dàng quản lý, mở rộng và hỗ trợ khách hàng
                          trong việc mua sắm trực tuyến. Khách hàng có thể tự
                          quản lý sản phẩm và đơn hàng mà không cần nhiều kiến
                          thức kỹ thuật. */}
                        </div>
                      </div>
                    </div>
                    <div className="class_scroll h-full flex items-center overflow-hidden">
                      <div className="w-full rounded-3xl border-solid border-[1px] border-[#262626] bg-custom-box md:px-10 p-3 md:p-10 max-h-full">

                        {/* image */}
                        <div className="flex w-full justify-center">
                          <img
                            src="/project/woo9.jpg"
                            alt="Chuyên viên phát triển web WordPress tại Đà Nẵng"
                            className="w-full md:w-10/12 h-auto pb-2"
                          ></img>
                        </div>

                        <div className="text">
                        Technologies and tools used:<br />
                        Platform: WordPress<br />
                        Plugin: WooCommerce, FlatsomeTheme<br />
                        Design tools: Adobe XD / Figma<br />
                        Payments: Stripe, PayPal<br />
                        I take care of the entire development process from idea consultation, choosing the right platform, UI design, to optimizing the user experience (UX).
                          {/* Các công nghệ và công cụ sử dụng:
                          <br />
                          Nền tảng: WordPress
                          <br />
                          Plugin: WooCommerce, Flatsome Theme
                          <br />
                          Công cụ thiết kế: Adobe XD / Figma
                          <br />
                          Payments: Stripe, PayPal
                          <br />
                          Tôi đảm nhiệm toàn bộ quá trình phát triển từ khâu tư
                          vấn ý tưởng, chọn nền tảng phù hợp, thiết kế UI, đến
                          việc tối ưu hóa trải nghiệm người dùng (UX). */}
                        </div>
                      </div>
                    </div>

                    {/* WebApp content */}
                    <div className="class_scroll h-screen flex items-center">
                      <div className="rounded-3xl border-solid border-[1px] border-[#262626] bg-custom-box md:px-10 p-3 md:p-10 max-h-full ">
                        <h2 className="WebApp title text-center">WebApp</h2>

                        {/* image */}
                        <div className="flex w-full justify-center">
                          <img
                            src="/project/web5.jpg"
                            alt="Thiết kế website chuẩn SEO tại Đà Nẵng"
                            className="w-full md:w-8/12 h-auto pb-2"
                          ></img>
                        </div>

                        <div className="text indent-3">
                          In the digital age, web applications (Web App) play an important role in providing convenient, fast and easy-to-use online solutions. This Web App development project is built to provide an optimal solution for managing, interacting, and processing online information for businesses and individual users.
                          {/* Trong kỷ nguyên số hóa, các ứng dụng web (Web App)
                          đóng vai trò quan trọng trong việc cung cấp các giải
                          pháp trực tuyến tiện lợi, nhanh chóng và dễ sử dụng.
                          Dự án phát triển Web App này được xây dựng nhằm cung
                          cấp giải pháp tối ưu cho việc quản lý, tương tác, và
                          xử lý thông tin trực tuyến cho doanh nghiệp và người
                          dùng cá nhân. */}
                        </div>
                      </div>
                    </div>

                    <div className="mb-5 class_scroll h-full flex items-center overflow-hidden">
                      <div className="w-full rounded-3xl border-solid border-[1px] border-[#262626] bg-custom-box md:px-10 p-3 md:p-10 max-h-full">

                        {/* image */}
                        <div className="flex w-full justify-center">
                          <img
                            src="/project/web2.jpg"
                            alt="Dịch vụ phát triển web theo yêu cầu"
                            className="w-full md:w-10/12 h-auto pb-2"
                          ></img>
                        </div>

                        <div className="text">
                          <p className="indent-3">
                            The Web App development project applies advanced technology to solve practical problems. With a friendly interface, powerful features and high security, this project not only helps optimize business processes but also brings a great experience to users.
                            {/* Dự án phát triển Web App áp dụng công nghệ tiên tiến
                            vào giải quyết các bài toán thực tiễn. Với giao diện
                            thân thiện, tính năng mạnh mẽ và khả năng bảo mật
                            cao, dự án này không chỉ giúp tối ưu hóa quy trình
                            kinh doanh mà còn mang lại trải nghiệm tuyệt vời cho
                            người dùng. */}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* AutoTool Content */}
                    <div className="class_scroll h-screen flex items-center overflow-hidden">
                      <div className="pt-16 pb-16 rounded-3xl border-solid border-[1px] border-[#262626] bg-custom-box md:px-10 p-3 md:p-10 max-h-full ">
                        <h2 className="AutoTool title text-center">AutoTool</h2>

                        {/* image */}
                        <div className="flex w-full justify-center">
                          <img
                            src="/project/tool.jpg"
                            alt="Lập trình website PHP, JavaScript, WordPress"
                            className="w-full md:w-10/12 h-auto pb-2"
                          ></img>
                        </div>

                        <div className="text">
                          <p className="indent-3">
                            With the growth of jobs that require performing a series of similar operations (such as data processing, software interaction, and file management), a desktop-based Auto Tool application was developed to help users automate repetitive tasks on the system.
                            {/* Với sự phát triển của các công việc yêu cầu thực
                            hiện hàng loạt các thao tác tương tự nhau (như xử lý
                            dữ liệu, tương tác phần mềm, và quản lý tệp). Một
                            ứng dụng tự động hóa (Auto Tool) trên nền tảng
                            desktop, được phát triển để giúp người dùng tự động
                            hóa các tác vụ lặp lại trên hệ thống. */}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="class_scroll h-screen flex items-center overflow-hidden">
                      <div className="pt-16 pb-16 rounded-3xl border-solid border-[1px] border-[#262626] bg-custom-box md:px-10 p-3 md:p-10 max-h-full overflow-auto">
                        <div className="text">
                          <p className="indent-3">
                            <strong>Results achieved </strong>
                          </p>
                          <p className="indent-3">
                            50% increase in work efficiency: Repetitive processes that previously required human intervention have been automated, saving time and effort. 90% reduction in manual errors: Thanks to the accuracy of the software, the rate of human errors has been significantly reduced. Flexible customization: Users can easily adjust automated processes to suit the specific needs of each task.
                            {/* Tăng 50% hiệu suất làm việc: Các quy trình lặp đi
                            lặp lại trước đây cần sự tham gia của con người đã
                            được tự động hóa, giúp tiết kiệm thời gian và công
                            sức. Giảm 90% lỗi thao tác thủ công: Nhờ sự chính
                            xác của phần mềm, tỷ lệ lỗi do con người thực hiện
                            đã giảm thiểu đáng kể. Tùy chỉnh linh hoạt: Người
                            dùng có thể dễ dàng điều chỉnh các quy trình tự động
                            để phù hợp với nhu cầu cụ thể của từng tác vụ. */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row w-[30px] md:w-3/12 md:h-screen flex md:flex-col md:gap-5 mb-4 justify-center items-center">
                  {renderButton("Ecommrce", "1.png")}
                  {renderButton("WebApp", "2.png")}
                  {renderButton("AutoTool", "3.png")}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Section>
    </Container>
  );
};

export default ProjectVertical;
