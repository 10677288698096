import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Đăng ký ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

function AnimatedText({ text, className="" }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const chars = [];

    // Tạo span cho từng ký tự và khoảng trắng
    text.split("").forEach((char) => {
      const span = document.createElement("span");
      if (char === " ") {
        span.innerHTML = "&nbsp;";
      } else {
        span.textContent = char;
        chars.push(span);
      }
      span.style.display = "inline-block";
      container.appendChild(span);
    });

    // Tạo timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        start: "top bottom-=100", // Bắt đầu khi phần trên của container cách bottom viewport 100px
        end: "bottom top+=100", // Kết thúc khi phần dưới của container cách top viewport 100px
        toggleActions: "play none none reverse",
      },
    });

    // Set perspective cho container
    gsap.set(container, { perspective: 400 });

    // Animate các ký tự (không bao gồm khoảng trắng)
    tl.from(chars, {
      duration: 0.8,
      opacity: 0,
      scale: 0,
      y: 80,
      rotationX: 180,
      transformOrigin: "0% 50% -50",
      ease: "back",
      stagger: 0.01,
    });

    // Cleanup function
    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
      tl.kill();
      container.innerHTML = "";
    };
  }, [text]);

  return <div className={className} ref={containerRef}></div>;
}

export default AnimatedText;
