import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // line-height: 1.5;
  font-size: 15px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  height: full;
`;

const HeaderNav = ({ onActive, handleScroll }) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const showSidebar = () => setIsSidebarVisible(true);
  const hideSidebar = () => setIsSidebarVisible(false);

  return (
    <Container>
      <Section className="section1">
        <div className="relative video1 z-30">
          <header
            id="headder"
            className="fixed m-auto max-h-[62px] w-full flex justify-around items-center z-20
            before:content-[''] before:absolute before:w-full before:h-full before:bg-[#8387a01a] before:backdrop-blur-3xl before:z-[-1]
            after:absolute after:left-[-100%] after:w-full after:h-full after:z-[-1] after:hover:left-full after:duration-500 after:bg-gradient-to-r from-transparent via-white to-transparent
            "
          >
            {/* Logo */}
            <div className="w-4/6 md:pl-10 text-white font-bold text-clamp-40 ">
              <a
                href="/"
                className="p-5 flex gap-5 items-center"
                onMouseOver={() => {
                  onActive(true);
                }}
                onMouseLeave={() => {
                  onActive(false);
                }}
              >
                {/* <img
                  src="/Img/logo.png"
                  className="w-2/6 md:w-[45px] h-[45px]"
                  alt="Logo"
                />
                Luan Vo */}
                <div className='flex justify-start items-end w-10/12 font-["Timberline"] text-[15px]'>
                  <svg
                    width="46"
                    height="66"
                    viewBox="0 0 238 335"
                    fill="none"
                    className="w-10 h-10 stroke-white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="b"
                      d="M226.43,261.7l-2.01,58.62-202.32,1.9m204.33-60.52l-29.8-17.77H43.08m-20.98,78.29l-19.6-17.37v-14.6m19.6,31.97h0M81.7,31.99V232.51h-14.12M81.7,31.99L51.17,8.22C28.76-2.82,16.03,3.26,2.5,16.76V243.93c.71,9.12,6.97,12.21,18.93,11.74m46.16-23.16l-20.39-6.98m-4.11,18.4V44.06c6.86-12.07,18.27-12.07,24.5,0V232.51h14.12V31.99c-23.39-19.37-34.5-18.89-52.54,1.9l-3.92,221.78c-1.15,5.51,2.6,11.24,3.92,13.65,1.72,3.13,5.97,4.26,13.92,3.81H213.45v31.73H22.1v17.37l202.32-1.9,2.01-58.62H73.51c-19.27,1.06-34.68-1.25-30.43-17.77V44.06m146.84,246.2H2.5l19.6,14.6-19.6-14.6H189.92l23.53,14.6-23.53-14.6v-12.6m-167.82,27.2v17.37h0"
                    />
                  </svg>
                  <div className="flex flex-col">
                    <div>Luan</div>
                    <div>Vo</div>
                  </div>
                </div>
              </a>
            </div>

            {/* Icon button menubar */}
            <label className="icons block md:hidden">
              <i
                className={`hover:bg-[#a6b1c68c] rounded-2xl p-1 ${
                  isSidebarVisible ? "hidden" : "flex"
                }`}
                onClick={showSidebar}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="26"
                  viewBox="0 96 960 960"
                  width="26"
                >
                  <path d="M120 816v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z" />
                </svg>
              </i>

              {/* Button close */}
              <i
                className={`hover:bg-[#a6b1c68c] rounded-2xl p-1 ${
                  isSidebarVisible ? "flex" : "hidden"
                }`}
                onClick={hideSidebar}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="26"
                  viewBox="0 96 960 960"
                  width="26"
                >
                  <path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                </svg>
              </i>
            </label>

            <div
              className={`absolute top-full w-1/2 right-0 font-medium
            md:relative md:top-auto md:w-3/6 md:h-full md:flex justify-around 
            ease-out duration-300 text-[20px] text-[#ffffffb3]
              ${
                isSidebarVisible
                  ? "bg-[#8387a01a] backdrop-blur-3xl h-screen w-1/2"
                  : "h-[0] md:h-full"
              }
            `}
            >
              <a
                href="/"
                className={`flex justify-center items-center h-[62px] px-5 text-center no-underline translate-y-[-50px] ease-out duration-300 
                  hover:text-white
                  ${
                    isSidebarVisible
                      ? "translate-y-[0] visible opacity-100"
                      : "translate-y-[-50px] md:translate-y-[0] invisible md:visible opacity-0 md:opacity-100"
                  }`}
                onMouseOver={() => {
                  onActive(true);
                }}
                onMouseLeave={() => {
                  onActive(false);
                }}
              >
                Home
              </a>
              <div
                className={`flex justify-center items-center h-[62px] px-5 text-center no-underline translate-y-[-50px] ease-out duration-300 
                  hover:text-white
                  ${
                    isSidebarVisible
                      ? "translate-y-[0] visible opacity-100"
                      : "translate-y-[-150px] md:translate-y-[0] invisible md:visible opacity-0 md:opacity-100"
                  }`}
              >
                <button
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    handleScroll("project");
                  }}
                  onMouseOver={() => {
                    onActive(true);
                  }}
                  onMouseLeave={() => {
                    onActive(false);
                  }}
                >
                  Project
                </button>
              </div>
              <div
                className={`flex justify-center items-center h-[62px] px-5 text-center no-underline translate-y-[-50px] ease-out duration-300 
                  hover:text-white
                  ${
                    isSidebarVisible
                      ? "translate-y-[0] visible opacity-100"
                      : "translate-y-[-150px] md:translate-y-[0] invisible md:visible opacity-0 md:opacity-100"
                  }`}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleScroll("info");
                  }}
                  onMouseOver={() => {
                    onActive(true);
                  }}
                  onMouseLeave={() => {
                    onActive(false);
                  }}
                >
                  Info
                </button>
              </div>
              <div
                className={`flex justify-center items-center h-[62px] px-5 text-center no-underline translate-y-[-50px] ease-out duration-300 
                  hover:text-white
                  ${
                    isSidebarVisible
                      ? "translate-y-[0] visible opacity-100"
                      : "translate-y-[-150px] md:translate-y-[0] invisible md:visible opacity-0 md:opacity-100"
                  }`}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleScroll("contact");
                  }}
                  onMouseOver={() => {
                    onActive(true);
                  }}
                  onMouseLeave={() => {
                    onActive(false);
                  }}
                >
                  Contacts
                </button>
              </div>
            </div>
          </header>
        </div>
      </Section>
    </Container>
  );
};

export default HeaderNav;
