import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // line-height: 1.5;
  font-size: 15px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  height: full;
`;

const HeaderNavTerm = ({ onActive }) => {
  return (
    <Container>
      <Section className="section1">
        <div className="relative video1 z-30">
          <header
            id="headder"
            className="fixed m-auto max-h-[62px] w-full flex justify-around items-center z-20
            before:content-[''] before:absolute before:w-full before:h-full before:bg-[#8387a01a] before:backdrop-blur-3xl before:z-[-1]
            after:absolute after:left-[-100%] after:w-full after:h-full after:z-[-1] after:hover:left-full after:duration-500 after:bg-gradient-to-r from-transparent via-white to-transparent
            "
          >
            {/* Logo */}
            <div className="md:pl-10 text-white font-bold text-clamp-40 ">
              <a
                href="/"
                className="p-5 flex gap-5 items-center"
                onMouseOver={() => {
                  onActive(true);
                }}
                onMouseLeave={() => {
                  onActive(false);
                }}
              >
                <div className='flex justify-start items-end w-10/12 font-["Timberline"] text-[15px]'>
                  <svg
                    width="46"
                    height="66"
                    viewBox="0 0 238 335"
                    fill="none"
                    className="w-10 h-10 stroke-white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="b"
                      d="M226.43,261.7l-2.01,58.62-202.32,1.9m204.33-60.52l-29.8-17.77H43.08m-20.98,78.29l-19.6-17.37v-14.6m19.6,31.97h0M81.7,31.99V232.51h-14.12M81.7,31.99L51.17,8.22C28.76-2.82,16.03,3.26,2.5,16.76V243.93c.71,9.12,6.97,12.21,18.93,11.74m46.16-23.16l-20.39-6.98m-4.11,18.4V44.06c6.86-12.07,18.27-12.07,24.5,0V232.51h14.12V31.99c-23.39-19.37-34.5-18.89-52.54,1.9l-3.92,221.78c-1.15,5.51,2.6,11.24,3.92,13.65,1.72,3.13,5.97,4.26,13.92,3.81H213.45v31.73H22.1v17.37l202.32-1.9,2.01-58.62H73.51c-19.27,1.06-34.68-1.25-30.43-17.77V44.06m146.84,246.2H2.5l19.6,14.6-19.6-14.6H189.92l23.53,14.6-23.53-14.6v-12.6m-167.82,27.2v17.37h0"
                    />
                  </svg>
                  <div className="flex flex-col">
                    <div>Luan</div>
                    <div>Vo</div>
                  </div>
                </div>
              </a>
            </div>
          </header>
        </div>
      </Section>
    </Container>
  );
};

export default HeaderNavTerm;
