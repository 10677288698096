import React, { useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  margin-left: auto;
  margin-right: auto;
  max-width: 1330px;
  width: 100%;
`;

const Fotter = ({ onActive }) => {
  gsap.registerPlugin(useGSAP);
  const content = useRef();

  return (
    <Container className="fotter_is_loading z-10 bg-[#0d0d0d]" ref={content}>
      <Section className="ct-section md:pb-[100px]">
        <footer className="w-full opacity-100 py-8 text-[#cccccc]">
          <div className="pb-10 flex flex-col md:flex-row justify-between items-center">
            {/* Logo */}
            <div className="mb-4 w-2/12">
              <div className='flex justify-end items-end w-10/12 font-["Timberline"] text-[10px]'>
                <svg
                  width="23"
                  height="33"
                  viewBox="0 0 238 335"
                  fill="none"
                  className="w-10 h-10 stroke-white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="b"
                    d="M226.43,261.7l-2.01,58.62-202.32,1.9m204.33-60.52l-29.8-17.77H43.08m-20.98,78.29l-19.6-17.37v-14.6m19.6,31.97h0M81.7,31.99V232.51h-14.12M81.7,31.99L51.17,8.22C28.76-2.82,16.03,3.26,2.5,16.76V243.93c.71,9.12,6.97,12.21,18.93,11.74m46.16-23.16l-20.39-6.98m-4.11,18.4V44.06c6.86-12.07,18.27-12.07,24.5,0V232.51h14.12V31.99c-23.39-19.37-34.5-18.89-52.54,1.9l-3.92,221.78c-1.15,5.51,2.6,11.24,3.92,13.65,1.72,3.13,5.97,4.26,13.92,3.81H213.45v31.73H22.1v17.37l202.32-1.9,2.01-58.62H73.51c-19.27,1.06-34.68-1.25-30.43-17.77V44.06m146.84,246.2H2.5l19.6,14.6-19.6-14.6H189.92l23.53,14.6-23.53-14.6v-12.6m-167.82,27.2v17.37h0"
                  />
                </svg>
                Luan Vo
              </div>
            </div>

            {/* Links */}
            <div
              className="flex mb-4 items-center gap-5 
                    flex-col md:flex-row"
            >
              <div>
                <p>
                  <a
                    href="mailto:voxuanluan97@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" hover:underline"
                    onMouseOver={() => {
                      onActive(true);
                    }}
                    onMouseLeave={() => {
                      onActive(false);
                    }}
                  >
                    Contact
                  </a>
                </p>
              </div>
              <div>
                <p>
                  <a
                    href="/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" hover:underline"
                    onMouseOver={() => {
                      onActive(true);
                    }}
                    onMouseLeave={() => {
                      onActive(false);
                    }}
                  >
                    Privacy & Cookie Policy
                  </a>
                </p>
              </div>
              <div>
                <p className="">
                  <a
                    href="/term"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" hover:underline"
                    onMouseOver={() => {
                      onActive(true);
                    }}
                    onMouseLeave={() => {
                      onActive(false);
                    }}
                  >
                    Terms & Conditions
                  </a>
                </p>
              </div>
            </div>

            {/* Copyright */}
            <div className="w-full md:w-2/12 flex justify-center">
              <p className="text-right pr-5 text-gray-400">
                @Copyright <span className="text-yellow-400">2024</span>
              </p>
            </div>
          </div>
        </footer>
      </Section>
    </Container>
  );
};

export default Fotter;
