import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import "./Contact.css";
import axiosInstance from "../../../axiosInstance";
import { ColorRing } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  color: #7d7f78;
`;

const Section = styled.section`
  padding-bottom: 150px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const Contact = ({onActive}) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const savedData = localStorage.getItem('backgroundData');
        if (savedData) {
            const formData = JSON.parse(savedData);
            for (const [key, value] of Object.entries(formData)) {
                setValue(key, value);
            }
        }
    }, [setValue]);

    const onSubmit = async (data) => {
        setIsLoading(true);

        try {
            const response = await axiosInstance.post('/mail/send-mail', data)
            if (response.status === 200) {
                // Thoong baso thanh cong o day:
                toast.success('Form submitted successfully!');
            }
            else {
                toast.error('Form submission failed. Please try again.');
            }
        } catch (error) {
            console.error('Error uploading data:', error);
            toast.error('Form submission failed. Please try again.');

        } finally {
            setIsLoading(false); // Kết thúc loading
        }
    };
    return (
        <Container className='content z-10 bg-[#0d0d0d]'>
            <Section className="contact-sc px-[25px] md:px-[50px]" style={{ opacity: "1", filter: "none" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='contacts pt-36'>
                        <div className='flex justify-center contacts text-[120px] font-normal'>
                            <h2 className=" text-transparent bg-clip-text p-5 md:p-32" >
                                Ready for your partnership
                            </h2>
                        </div>
                        <div>
                            <div className='flex justify-end items-end w-10/12 font-["Timberline"] text-[10px]'>
                                <svg width="23" height="33" viewBox="0 0 238 335" fill="none"
                                    className='w-10 h-10 stroke-white'
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path className="b"
                                        d="M226.43,261.7l-2.01,58.62-202.32,1.9m204.33-60.52l-29.8-17.77H43.08m-20.98,78.29l-19.6-17.37v-14.6m19.6,31.97h0M81.7,31.99V232.51h-14.12M81.7,31.99L51.17,8.22C28.76-2.82,16.03,3.26,2.5,16.76V243.93c.71,9.12,6.97,12.21,18.93,11.74m46.16-23.16l-20.39-6.98m-4.11,18.4V44.06c6.86-12.07,18.27-12.07,24.5,0V232.51h14.12V31.99c-23.39-19.37-34.5-18.89-52.54,1.9l-3.92,221.78c-1.15,5.51,2.6,11.24,3.92,13.65,1.72,3.13,5.97,4.26,13.92,3.81H213.45v31.73H22.1v17.37l202.32-1.9,2.01-58.62H73.51c-19.27,1.06-34.68-1.25-30.43-17.77V44.06m146.84,246.2H2.5l19.6,14.6-19.6-14.6H189.92l23.53,14.6-23.53-14.6v-12.6m-167.82,27.2v17.37h0" />
                                </svg>
                                Luan Vo
                            </div>
                            <div className='w-full flex justify-center'>
                                <div id="thanh_den" style={{ marginRight: 0, width: "70%" }}></div>
                            </div>
                        </div>

                        <div className='flex justify-center text-center'>
                            <div className="form-container md:w-6/12">
                                <div className="form-contacts">
                                    <div className="form-group">
                                        <input type="text" placeholder='Full Name * ' {...register("full_name", { required: "Full Name is require!" })} 
                                            onMouseOver={() => { onActive(true) }} onMouseLeave={() => { onActive(false) }}/>
                                        {errors.full_name && <div style={{ color: "red" }}>{errors.full_name.message}</div>}
                                    </div>
                                </div>
                                <div className="form-contacts">
                                    <div className="form-group">
                                        <input type="email" placeholder='Email * '{...register("Email", { required: "Email is require!" })} 
                                            onMouseOver={() => { onActive(true) }} onMouseLeave={() => { onActive(false) }}/>
                                        {errors.Email && <div style={{ color: "red" }}>{errors.Email.message}</div>}
                                    </div></div>

                                <div className="form-contacts">
                                    <div className="form-group">
                                        <input type="text" placeholder='Tel * '{...register("Tel", { required: "Tel is require!" })} 
                                            onMouseOver={() => { onActive(true) }} onMouseLeave={() => { onActive(false) }}/>
                                        {errors.Tel && <div style={{ color: "red" }}>{errors.Tel.message}</div>}
                                    </div>
                                </div>
                                <div className="form-contacts">
                                    <div className="form-group">
                                        <input type="text" placeholder='Message * '{...register("Message", { required: "Message is require!" })} 
                                            onMouseOver={() => { onActive(true) }} onMouseLeave={() => { onActive(false) }}/>
                                        {errors.Message && <div style={{ color: "red" }}>{errors.Message.message}</div>}
                                    </div>
                                </div>
                                <div className='text-[#68628d] px-10'>
                                    {/* <p>Xin lưu ý rằng ngân sách tối thiểu cho một dự án là €3.500 ròng và thời gian chờ đợi trung bình cho đến khi dự án bắt đầu là khoảng 4 tuần. Vì chúng tôi xác định rất rõ các dự án của khách hàng và hỗ trợ khách hàng lâu dài nên khả năng tiếp nhận khách hàng mới của chúng tôi bị hạn chế. Vui lòng chỉ liên hệ với chúng tôi nếu bạn muốn hợp tác lâu dài .</p> */}
                                    <p className='p-0'>We also hope you understand that we generally do not respond to promotional messages.</p>

                                </div>
                                <div>
                                    <input type="checkbox" required="" {...register("Checkbox", { required: "Please agree to the terms and conditions before submitting the form!" })}
                                        onMouseOver={() => { onActive(true) }} onMouseLeave={() => { onActive(false) }} ></input>
                                    <font> I agree that this website stores the information I submit so that it can respond to my request. <font style={{ color: "red" }}>*</font></font>
                                    {errors.Checkbox && <div style={{ color: "red" }}>{errors.Checkbox.message}</div>}
                                </div>
                                <div className='flex justify-center pt-10'>
                                <button type="submit" className='relative overflow-hidden bg-[#161616] flex items-center justify-center gap-2 p-3 w-6/12 rounded-xl
                                    hover:text-green-300 hover:bg-[#759a76] hover:delay-1000 hover:shadow-green group'
                                    onMouseOver={() => { onActive(true) }} onMouseLeave={() => { onActive(false) }}>
                                    <span className="absolute top-0 -left-full w-full h-[2px] bg-gradient-to-r from-transparent to-[#759a76] group-hover:left-full duration-1000"></span>
                                    <span className="absolute -top-full right-0 w-[2px] h-full bg-gradient-to-b from-transparent to-[#759a76] group-hover:top-full group-hover:delay-[0.25s] duration-1000"></span>
                                    <span className="absolute bottom-0 -right-full w-full h-[2px] bg-gradient-to-l from-transparent to-[#759a76] group-hover:right-full group-hover:delay-500 duration-1000"></span>
                                    <span className="absolute -bottom-full left-0 w-[2px] h-full bg-gradient-to-t from-transparent to-[#759a76] group-hover:bottom-full group-hover:delay-[0.75s] duration-1000"></span>
                                    Submit
                                </button>
                            </div>
                            </div>
                        </div>

                    </div>
                </form>

                <ToastContainer />
                {isLoading && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgb(75 81 99 / 80%)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999
                    }}>
                        <ColorRing
                            height={70}
                            width={70}
                            color="#4fa94d"
                            ariaLabel="loading-indicator"
                        />
                    </div>
                )}
            </Section>
        </Container>
    );
};

export default Contact;